import { DatePipe } from '@angular/common';
import { Component, ViewChild, ElementRef, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';
import { MainService } from 'src/app/MyService/main.service';

@Component({
  selector: 'app-first-review',
  templateUrl: './first-review.component.html',
  styleUrls: ['./first-review.component.css']
})
export class FirstReviewComponent implements OnInit, OnChanges {

  @ViewChild('chartCanvas', { static: true }) chartCanvas!: ElementRef;
  user_id = localStorage.getItem('id');
  data: any;
  review: any = {};
  name = localStorage.getItem('userName');
  formattedDate: string = '';
  month: any; // Ensure this is set appropriately
  profile: any;
  profilePhoto: any;
  @Input() userId: any;

  constructor(private mainService: MainService, private datePipe: DatePipe) {}

  ngOnInit() {
    const newId = this.user_id || this.userId;
    console.log(`ngOnInit - User ID: ${newId} at ${new Date().toLocaleString()}`);

    // Fetch performance data
    this.mainService.dataPerformance(newId).subscribe(
      (res: any) => {
        this.data = res;
        console.log(`dataPerformance - Data for User ID: ${newId} at ${new Date().toLocaleString()}`, this.data);
        this.renderChart();
      },
      error => {
        console.error(`ngOnInit - Error fetching performance data for User ID: ${newId}`, error);
      }
    );

    this.rating();
    this.report();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userId'] && !changes['userId'].isFirstChange()) {
      console.log(`ngOnChanges - userId changed to: ${this.userId} at ${new Date().toLocaleString()}`);
      this.rating();
      this.report();
    }
  }

  rating() {
    const newId =  this.userId||this.user_id ;
    this.mainService.performance(newId, this.month).subscribe(
      (pro: any) => {
        this.profile = pro;
        console.log("Rating - Review Data: ", this.profile);
        console.log(`Rating - Performance for User ID: ${newId} at ${new Date().toLocaleString()}`, this.profile);
      },
      error => {
        console.error(`rating - Error fetching performance data for User ID: ${newId}`, error);
      }
    );
  }

  report() {
    const newId = this.userId || this.user_id;
    console.log(`report - Fetching report for User ID: ${newId} at ${new Date().toLocaleString()}`);

    this.mainService.report(newId).subscribe(
      (res: any) => {
        this.review = res[0]; // Assuming the first item in the array
        // this.formattedDate = this.formatDate(this.review.createdOn);
        console.log(`Report - Full Data for User ID: ${newId} at ${new Date().toLocaleString()}`, this.review);
      },
      error => {
        console.error(`report - Error fetching report for User ID: ${newId}`, error);
      }
    );
  }

  formatDate(timestamp: number): string {
    return this.datePipe.transform(timestamp, 'MMMM d \'at\' h:mm a') ?? '';
  }

  renderChart() {
    if (!this.data || !Array.isArray(this.data)) {
      console.warn('renderChart - No data available for rendering chart');
      return;
    }

    const monthMapping: { [key: string]: string } = {
      'JANUARY': 'Jan',
      'FEBRUARY': 'Feb',
      'MARCH': 'Mar',
      'APRIL': 'Apr',
      'MAY': 'May',
      'JUNE': 'Jun',
      'JULY': 'Jul',
      'AUGUST': 'Aug',
      'SEPTEMBER': 'Sep',
      'OCTOBER': 'Oct',
      'NOVEMBER': 'Nov',
      'DECEMBER': 'Dec'
    };
    const months = this.data.map((entry: any) => monthMapping[entry.Month]);
    const scores = this.data.map((entry: any) => entry['Obtained Score']);

    const chartData = {
      labels: months,
      datasets: [{
        label: 'Monthly Report',
        data: scores,
        fill: true,
        borderColor: '#6391ff',
        backgroundColor: 'rgba(99, 145, 255, 0.2)',
      }]
    };

    const chartOptions = {
      scales: {
        y: {
          beginAtZero: true,
        }
      }
    };

    console.log(`renderChart - Rendering chart for User ID: ${this.user_id} at ${new Date().toLocaleString()}`);

    new Chart(this.chartCanvas.nativeElement, {
      type: 'line',
      data: chartData,
      options: chartOptions,
    });
  }
}
