// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase : {
    apiKey: "AIzaSyBAcxJd0SbmqUxAQ1Rq5oXohfj9e9MAY08",
    authDomain: "hr-console.firebaseapp.com",
    databaseURL: "https://hr-console-default-rtdb.firebaseio.com",
    projectId: "hr-console",
    storageBucket: "hr-console.appspot.com",
    messagingSenderId: "971066695555",
    appId: "1:971066695555:web:744c5554b3364c7bd460b6",
    measurementId: "G-FW18QTKKT3",
    vapidKey: "BMVWEz8LQs8itJwCI2xHlg5sq1yah_lWivafa3r_FJ6r3iSfE-sjNxNTazmX-wZ8GrZ43MZJ3WIf9bCeJuWgLZk"
  },
  cloudinary: {
    apiKey: '974981595445112',
    apiSecret: '4URnjaut9IehzWDZZ8_AVH8pKoQ', // Replace with your actual API secret
    cloudName: 'dzvsmmraz'
  },
  sentry: {
    dsn: 'https://215c72dcf1cc7e9534590f4e675c0556@o4508319798001664.ingest.us.sentry.io/4508319808552960',
    release: '2.0'
  },
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
