import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
// import { ProjectAssignModalComponent } from '../project-assign-modal/project-assign-modal.component';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
// import { AddNotesComponent } from '../add-notes/add-notes.component'
// import { AddStagesComponent } from '../add-stages/add-stages.component';

@Component({
  selector: 'app-admin-details-project',
  templateUrl: './admin-details-project.component.html',
  styleUrls: ['./admin-details-project.component.css']
})
export class AdminDetailsProjectComponent implements OnInit {
  projectId: string;
  isPriority: boolean;
  status: string;
  assignToId: any;
  pageSize: number = 10;
  pageNumber: number = 0;
  data: any[] = [];
  totalElements: number = 0;
  details: any;
  member: any[] = [];
  files: any[] = [];
  isEditing: boolean = false;
  description: string;
  headings:any;
  stage:any;
  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private mainService: MainService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.projectId = params['id'];
      this.fetchData2();
      this.stages();
    });
  }

  fetchData2(): void {
    this.mainService.getAssignmentById(this.projectId).subscribe({
      next: (res: any) => {
        this.details = res;
        this.member = res.Users;
        this.description = this.details?.description||'';
        this.headings = this.details?.headings||'';
        this.changeDetectorRef.detectChanges(); 
      },
      error: (error) => {
        console.error('Error fetching project details:', error);
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    });
  }

  stages():void{
this.mainService.getAllStageById(this.projectId,this.pageNumber,this.pageSize).subscribe((allstages:any)=>{
  this.stage=allstages.content;
})
  }
  saveDescription(): void {
    if (!this.isEditing) return;
  
    this.isEditing = false;
    const payload = { description: this.description };
  
    this.mainService.updateDescription(this.projectId, payload).subscribe({
      next: (res: any) => {
        this.fetchData2(); 
      },
      error: (error) => {
        console.error('Error updating description:', error);
        alert('Failed to update description. Please try again.');
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    });
  }
  

  cancelEdit(): void {
    this.isEditing = false;
  }

  getPriorityColor(priority: string | null): string {
    switch (priority?.toLowerCase()) {
      case 'high':
        return '#FF0000';
      case 'medium':
        return '#007bff';
      case 'low':
      case null:
        return '#28a745';
      default:
        return '#28a745';
    }
  }

  getFileIcon(file: string): string {
    const extension = file.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return '../../../../assets/icons/pdf.png';
      case 'xls':
      case 'xlsx':
        return '../../../../assets/icons/excel.png';
      case 'jpg':
        return '../../../../assets/task.png';
      case 'jpeg':
        return '../../../../assets/task.png';
      case 'png':
        return '../../../../assets/task.png';
      case 'gif':
        return '../../../../assets/task.png';
      default:
        return '../../../../assets/default.png';
    }
  }

  // openDialog(): void {
  //   this.dialog.open(AddStagesComponent, {
  //     width: '600px',
  //     data: { projectId: this.projectId }
  //   });
  // }
  // updateStage(stageId):void{
  //   this.dialog.open(AddStagesComponent,{
  //   width:'600px',  
  //   data:{
  //    projectId: this.projectId,
  //    stageId:stageId
  //   }
  //   });
  // }
  
  // openDialogTask():void{
  //   this.dialog.open(ProjectAssignModalComponent, {
  //     width: '800px',
  //     data: { projectId: this.projectId }
  //   });
  // }
  // openDialognotes(noteIndex: number): void {
  //   const selectedNote = this.details.notes[noteIndex];

  //   this.dialog.open(AddNotesComponent, {
  //     width: '800px',
  //     data: {
  //       projectId: this.projectId,
  //       note: selectedNote,
  //       noteIndex: noteIndex
  //     }
  //   });
  // }

  // openDialogNote(): void {
  //   this.dialog.open(AddNotesComponent, {
  //     width: '800px',
  //     data: { projectId: this.projectId }
  //   });
  // }

  // openDialogDescription(): void {
  //   this.isEditing = true;
  // }
}
