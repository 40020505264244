<div class="row">
  <div class="flex justify-between align-center ">
    <h2 class="m-0">Current Openings</h2>
    <div class="filter">
      <button class="hiring-btn" (click)="openModalDialog()"> <span style="color:blue">+ </span> New Hiring</button>
      <button 
      mat-button 
      class="status-btn" 
      [matMenuTriggerFor]="menu"
      aria-label="Status dropdown button"
    >
      Status <mat-icon>arrow_drop_down</mat-icon>
    </button>

          <mat-menu #menu="matMenu" >
            <button
              mat-menu-item
              class="menu-item active-item" 
            >
              <mat-icon>check_circle</mat-icon>
              <span>Active</span>
            </button>
            <button
              mat-menu-item
              class="menu-item inactive-item"
            >
              <mat-icon>cancel</mat-icon>
              <span>InActive</span>
            </button>
          </mat-menu>
       
    </div>
  </div>
  
  <div class="container container1">
    <div class="scrollable-content">
      <div class="all-card">
        <div class="card" *ngFor="let candidate of candidates">
          <div class="card-header">
            <img src="../../../../assets/Rectangle.png" alt="GitHub" />
            <div class="card-title" [matTooltip]="isTruncated( candidate.designation)?candidate.designation:''"
             [matTooltipDisabled]="!isTruncated(candidate.designation)" >
              {{ candidate.designation | truncate:15  }}<br />
              <span>posted {{ candidate.createdDate | timeAgo }}</span>
            </div>
            <div class="dots">
              <button style="background: none; top: -9px;"
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Example icon button with a vertical three dot icon"
                class="menu-button"
              >
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #menu="matMenu" class="custom-menu">
                <button
                  mat-menu-item
                  class="menu-item active-item"
                  (click)="updateStatus(candidate.vacancyId, 'true')"
                >
                  <mat-icon>check_circle</mat-icon>
                  <span>Active</span>
                </button>
                <button
                  mat-menu-item
                  class="menu-item inactive-item"
                  (click)="updateStatus(candidate.vacancyId, 'false')"
                >
                  <mat-icon>cancel</mat-icon>
                  <span>InActive</span>
                </button>
              </mat-menu>
            </div>
            <!-- updatevacancy -->
          </div>
          <div class="info"   >
            <div class="card-info">
              <span
                ><img src="../../../../assets/location (1).png" />
                {{ candidate.location }}</span
              >
              <div class="flex justify-between">
              <span><img src="../../../../assets/employee.png" />
                {{ candidate.experience }}</span>
              <h3 class="m-0 text-white flex font-medium">{{ candidate.applicationCount }} <span class="text-xs">applications </span></h3>
            </div>
            </div>
          </div>
        </div>
        <!-- <div class="card">
          <div class="add-new" (click)="openModalDialog(null)">Add New</div>
        </div> -->
      </div>
    </div>
  </div>
 
 <div >
  <div class="flex justify-between align-center mb-4">
    <h2 class="m-0">Candidates</h2>
    <div class="filter">
      <button class="hiring-btn" (click)="openSaveDialog()"> <span style="color:blue">+ </span> New Candidate</button>
      <button class="hiring-btn" (click)="toggleFilterForm()">
        <mat-icon>filter_list</mat-icon> Filter
      </button>
      <!-- <button 
      mat-button 
      class="status-btn" 
      [matMenuTriggerFor]="menu"
      aria-label="Status dropdown button"
    >
      Status <mat-icon>arrow_drop_down</mat-icon>
    </button>

          <mat-menu #menu="matMenu" >
            <button
              mat-menu-item
              class="menu-item active-item" 
            >
              <mat-icon>check_circle</mat-icon>
              <span>Active</span>
            </button>
            <button
              mat-menu-item
              class="menu-item inactive-item"
            >
              <mat-icon>cancel</mat-icon>
              <span>InActive</span>
            </button>
          </mat-menu> -->
       
    </div>
    <div class="filter-form-wrapper" *ngIf="showFilterForm" >
      <div class="filter-btn flex justify-between text-white bg-[#262626] m-0">
         <h2 class="m-0">Filter Candidate</h2>
         <button class="bg-transparent p-0 flex items-center justify-center" (click)="toggleFilterForm()">
           <mat-icon>close</mat-icon>
         </button>
       </div>
     
       <form #filterForm="ngForm" (ngSubmit)="meetingFilter()" >
        <div class="flex justify-between  ">
          <button mat-button class="custom-button">
            <!-- Designation -->
            <select [(ngModel)]="designation" (change)="applyFilters()">
              <option [value]="null">Designation</option>
              <option
                *ngFor="let candidate of candidates"
                [value]="candidate.designation"
              >
                {{ candidate.designation }}
              </option>
            </select>
          </button>
        </div>
         <!-- <div class="form-group" >
           <label for="dateRange" >Select Date:</label><br>
           <div style="display: flex;">
             <input
               id="startDate"
               name="startDate"
               [(ngModel)]="startDate"
               type="date"
               class="form-control"
               style=" margin-right: 10px;"
             />
             <input
               id="endDate"
               name="endDate"
               [(ngModel)]="endDate"
               type="date"
               class="form-control"
             />
           </div>
         </div>
     
         <div class="form-group" >
           <label for="status" >Status:</label><br>
           <select id="status" name="status" [(ngModel)]="status" class="form-control" class="selector">
             <option value="" disabled selected>Select Status</option>
             <option value="PENDING">Pending</option>
               <option value="DONE">Done</option>
               <option value="DUE">Due</option>
               <option value="REVIEWED">Reviewed</option>
               <option value="ASSIGNED">Assigned</option>
           </select>
         </div>
         <div class="form-group">
           <label for="status" >Select Employee:</label><br>
           <select id="userId" name="employeeId" [(ngModel)]="user_id" class="form-control" class="selector">
             <option value="" disabled selected>Select Employee</option>
             <option *ngFor="let employee of user" [value]="employee.id">{{ employee.userName }} </option>
           </select>
         </div>
         <div class="filter-btn">
           <button mat-button color="warn" (click)="clearFilters()">Clear</button>
           <button mat-raised-button color="accent" type="submit" style="width: 100%;">Filter</button>
         </div> -->
     
       </form>
     
     </div>
  </div>
 </div>
  <!-- <div
    class="header-row"
  >
    <h1>
      Candidates<span (click)="toggleFilters()"
        ><i
          style="
            font-size: 16px;
            color: grey;
            margin-left: 10px;
            cursor: pointer;
          "
          class="fa"
          >&#xf107;</i
        ></span
      >
    </h1>
  </div> -->
 
 
  <!-- <div
    class="button-group"
    style="display: flex; gap: 10px; width: 100%; overflow-y: auto"
  >
    <div *ngIf="showFilters" class="filter-container" style="display: contents">
      <button mat-button class="custom-button" (click)="openSaveDialog()">
        <img src="../../../../assets/add.png" /> Add new
      </button>

      <button mat-button class="custom-button">
        Designation
        <select [(ngModel)]="designation" (change)="applyFilters()">
          <option [value]="null">All</option>
          <option
            *ngFor="let candidate of candidates"
            [value]="candidate.designation"
          >
            {{ candidate.designation }}
          </option>
        </select>
      </button>

      <button mat-button class="custom-button">
        Status
        <select [(ngModel)]="status" (change)="applyFilters()">
          <option value="SELECTED">Selected</option>
          <option value="REJECTED">Rejected</option>
          <option value="PENDING">Pending</option>
          <option value="INPROGRESS">In Progress</option>
          <option value="HIRED">Hired</option>
          <option value="SCREENING">Screening</option>
        </select>
      </button>

      <button mat-button class="custom-button">
        Start Date
        <input type="date" [(ngModel)]="startDate" (change)="applyFilters()" />
      </button>

      <button mat-button class="custom-button">
        End Date
        <input type="date" [(ngModel)]="endDate" (change)="applyFilters()" />
      </button>
      <button mat-button class="custom-button">
        Active/Inactive
        <select [(ngModel)]="isActiveVacancy" (change)="applyFilters()">
          <option [value]="null">All</option>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </button>
      <button mat-button class="custom-button">
        AI Status
        <select [(ngModel)]="hiringAIStatus" (change)="applyFilters()">
          <option value="SELECTED">Selected</option>
          <option value="REJECTED">Rejected</option>
          <option value="AVERAGE">Average</option>
        </select>
      </button>
    </div>
  </div> -->
  <div class="container-fluid">
    
        <span class="nav-item" (click)="filterStatus('--')">All</span>
        <span class="nav-item" (click)="filterStatus('SELECTED')"
          >Accepted</span
        >
        <span class="nav-item" (click)="filterStatus('REJECTED')"
          >Rejected</span
        >
     
  </div>
  <div class="container2">
    <div class="scrollable-content">
      <table class="candidates-table">
        <thead>
          <tr>
            <th (click)="sortCandidatesById()" > <mat-icon>{{ sortOrder === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>E. ID</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Source</th>
            <th>Score</th>
            <!-- <th (click)="sortCandidatesByDates()"><mat-icon>{{sortedByDateAsc === 'asc' ? 'arrow_upward' : 'arrow_downward'}}</mat-icon> Score</th> -->
            <th >Date</th>
            <th>URLS</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let candidate of hiring">
            <td>{{ candidate.id }}</td>
            <td>
              {{ candidate.name }}
              <a [href]="candidate.resume" target="_blank">
                <img
                  src="../../../../assets/attach-file.png"
                  alt="Resume"
                  style="margin-left: 10px"
                />
              </a>
            </td>

            <td>{{ candidate.designation }}</td>
            <td>{{ candidate.createdByName }}</td>
            <!-- <td>
            <div class="acceptance-score">
              {{ candidate.matchedPercentage ? }}%
            </div>
          </td> -->
            <td>
              <div class="acceptance-score">
                <mat-icon
                  style="font-size: 9px"
                  [ngClass]="{
                    green: candidate.progressStatus === 'INPROGRESS',
                    red: candidate.progressStatus === 'REJECTED',
                    magenta: candidate.progressStatus === 'SELECTED'
                  }"
                  >circle</mat-icon
                >
                {{
                  candidate.matchedPercentage
                    ? candidate.matchedPercentage.toFixed(2)
                    : "0.00"
                }}%
              </div>
            </td>

            <td>{{ candidate.createdTime | date : "dd/MM/yy" }}</td>

            <td>
              <div class="urls">
                <a
                  *ngIf="hasUrl(candidate.urls, 'github')"
                  [href]="getUrl(candidate.urls, 'github')"
                  target="_blank"
                >
                  <img src="../../../../assets/github.png" alt="GitHub" />
                </a>
                <a
                  *ngIf="hasUrl(candidate.urls, 'linkedin')"
                  [href]="getUrl(candidate.urls, 'linkedin')"
                  target="_blank"
                >
                  <img src="../../../../assets/linkedin.png" alt="LinkedIn" />
                </a>
                <a
                  *ngIf="hasUrl(candidate.urls, 'portfolio')"
                  [href]="getUrl(candidate.urls, 'portfolio')"
                  target="_blank"
                >
                  <img src="../../../../assets/twitter.png" alt="Portfolio" />
                </a>
              </div>
            </td>
            <td>{{ candidate.autoStatus }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="pagination-container">
    <mat-paginator
      [length]="totalElements"
      [pageSize]="pageSize"
      [pageSizeOptions]="[10, 20, 50, 100]"
      (page)="onPageChange($event)"
    >
    </mat-paginator>
  </div>
</div>
