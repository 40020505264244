import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import { MatPaginatorModule } from '@angular/material/paginator';
import { UpdateSubtaskComponent } from '../../manager/update-subtask/update-subtask.component';
import { AddSubtaskUserComponent } from '../add-subtask-user/add-subtask-user.component';

@Component({
  selector: 'app-sub-checker',
  templateUrl: './sub-checker.component.html',
  styleUrls: ['./sub-checker.component.css']
})
export class SubCheckerComponent implements OnInit {

 
  assignToId = localStorage.getItem('id');
  assignments: any[] = [];  // Consistently use this array
  totalElements: number = 0;
  status: string;
  pageSize: number = 10;
  pageNumber: number = 0;
  assignmentId: any;
  isPriority: boolean;
  selectedTask: any = null;
  isTaskDetailsVisible = false;
  story: string[] = [];

  constructor(private dialog: MatDialog, private mainService: MainService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.assignmentId = params['assignId'];
      this.fetchData();
    });
   
  }

  fetchData(): void {
    if (this.assignmentId) {
      this.mainService.getAllAssignmentDetails(
        this.assignmentId,
        this.isPriority,
        this.status,
        this.assignToId,
        this.pageNumber,
        this.pageSize
      ).subscribe((res: any) => {
        this.assignments = res.content;
        this.totalElements = res.totalElements;
        console.log("API Response:", JSON.stringify(res));
       
      }, error => {
        console.error("Error fetching data:", error);
      });
    } else {
      console.warn("Assignment ID is not available.");
    }
  }


  toggleTaskDetails(taskId: any): void {
    this.isTaskDetailsVisible = !this.isTaskDetailsVisible;
    if (this.isTaskDetailsVisible) {
      this.selectedTask = this.assignments.find(task => task.id === taskId);
      this.story = this.selectedTask ? this.selectedTask.story : [];
    }
  }
  // updateTask():void{
  //   this.dialog.open()
  // }
  updateTask(subtaskId):void{
    console.log("subtaskId "+ subtaskId)
    this.dialog.open(AddSubtaskUserComponent,{
      width:"700px",
      height:"500px",
      data:{
        subtask : subtaskId,
        projectId :this.assignmentId
      }
    })
  }
  clickget(){
    console.log("assigntoid"+ this.assignToId)
    console.log("")
  }
  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData();
  }

  navigateToDashboard() {
    this.router.navigate(['/user-dashboard']);
  }
}
