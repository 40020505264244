  <mat-dialog-content>
      <div (click)="closeModal()"></div>
      <div class="modal-content">
        <span class="close" (click)="closeModal()" style="color: white;cursor: pointer;float: right;">&times;</span>
        <h2 style="color: white;">{{ noteIndex !== null ? 'Update Note' : 'Add Notes' }}</h2>
        <form (ngSubmit)="formSubmit()" #notesForm="ngForm">
          <label for="heading">Notes</label>
          <textarea type="text" id="heading" name="heading" [(ngModel)]="note" required></textarea>
          <button type="submit" [disabled]="!notesForm.form.valid">Save</button>
        </form>
      </div>
    </mat-dialog-content>
    