import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';
import { MainService } from 'src/app/MyService/main.service';

@Component({
  selector: 'app-second-review',
  templateUrl: './second-review.component.html',
  styleUrls: ['./second-review.component.css']
})
export class SecondReviewComponent implements OnInit, OnChanges {
  user_id = localStorage.getItem('id');
  month: any;
  attendanceData: any;

  @Input() userId: any;
  @ViewChild('attendanceChart') attendanceChartRef!: ElementRef<HTMLCanvasElement>;
  @ViewChild('meetingChart') meetingChartRef!: ElementRef<HTMLCanvasElement>;

  constructor(private mainService: MainService) {}

  ngOnInit(): void {
    console.log('ngOnInit called');
    const newId = this.userId || this.user_id;

    this.mainService.performance(newId, this.month).subscribe(
      (res: any) => {
        console.log('API response:', res);
        this.attendanceData = res;
        this.createAttendanceChart();
        this.createMeetingChart();
      },
      (error) => {
        console.error('API error:', error);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userId'] && !changes['userId'].isFirstChange()) {
      console.log(`ngOnChanges - userId changed to: ${this.userId} at ${new Date().toLocaleString()}`);
      this.fetchAttendanceData(); // Fetch data when userId changes
    }
  }

  private fetchAttendanceData(): void {
    const newId = this.userId || this.user_id;
    this.mainService.performance(newId, this.month).subscribe(
      (res: any) => {
        console.log('API response:', res);
        this.attendanceData = res;
        this.createAttendanceChart();
        this.createMeetingChart();
      },
      (error) => {
        console.error('API error:', error);
      }
    );
  }

  private createAttendanceChart(): void {
    if (!this.attendanceData) {
      console.warn('No attendance data available for chart creation');
      return;
    }

    console.log('Creating attendance chart with data:', this.attendanceData);
    const attendanceChartCanvas = this.attendanceChartRef.nativeElement;

    new Chart(attendanceChartCanvas, {
      type: 'doughnut',
      data: {
        labels: ['Attendance', 'Half Day', 'Absent','Late'],
        datasets: [{
          data: [
            this.attendanceData.presentDays || 0,
            this.attendanceData.halfDay || 0,
            this.attendanceData.absentDays || 0,
            this.attendanceData.monthlyLateCount || 0
          ],
          backgroundColor: ['#007bff', '#ffc107', '#333','#FF0000'],
          borderWidth: 0
        }]
      },
      options: {
        cutout: 70,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }

  private createMeetingChart(): void {
    if (!this.attendanceData) {
      console.warn('No attendance data available for chart creation');
      return;
    }

    console.log('Creating meeting chart with data:', this.attendanceData);
    const meetingChartCanvas = this.meetingChartRef.nativeElement;

    new Chart(meetingChartCanvas, {
      type: 'doughnut',
      data: {
        labels: ['Presence', 'Absent'],
        datasets: [{
          data: [
            this.attendanceData.presentMeetings || 0,
            this.attendanceData.absentMeetings || 0,
          ],
          backgroundColor: ['#007bff', '#ffc107'],
          borderWidth: 0
        }]
      },
      options: {
        cutout: 70,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }
}
