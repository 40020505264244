import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/MyService/main.service';


@Component({
  selector: 'app-accouncement-module',
  templateUrl: './accouncement-module.component.html',
  styleUrls: ['./accouncement-module.component.css']
})
export class AccouncementModuleComponent implements OnInit {

  events: any[] = [];
  modalVisible: boolean = false;

  constructor(private mainService: MainService,private dialogRef: MatDialogRef<AccouncementModuleComponent>) { }

  ngOnInit(): void {
    this.mainService.newevent().subscribe(
      (res: any[]) => {
        this.events = res;
      },
      (error) => {
        console.error('Error fetching announcement:', error);
      }
    );
  }

  closeModal(): void {
    this.dialogRef.close();
}

}
