<!-- <div class="container">
  <div class="growth-container" >
    <h2>MoM Growth Rate </h2>
    <div class="legend">
      <span class="dot"></span>Monthly Report
    </div>
    <div class="chart">
      <canvas #chartCanvas ></canvas>
    </div>
  </div>
</div> -->
  
<div class="container">
    <div class="growth-container">
      <h2>MoM Growth Rate</h2>
      <div class="legend">
        <span class="dot"></span> Monthly Report
      </div>
      <div class="chart">
        <!-- <canvas #chartCanvas></canvas> -->
        <ngx-charts-line-chart
        [results]="barChartData"
        [scheme]="colorScheme"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [animations]="animations"
        [autoScale]="true"
        [roundDomains]="roundDomains"
        [showGridLines]="showGridLines"
        [rotateXAxisTicks]="rotateXAxisTicks"
        [gradient]="useGradients"
        [wrapTicks]="wrapTicks">
      </ngx-charts-line-chart>
      
  
      
      
      </div>
    </div>
  </div>
  