<div class="full-page">
  <h4>Hiring</h4>
  <div class="header-row" style="display: flex; justify-content: space-between;  align-items: center;  width: 100%; height: 50px; border-radius: 8px;  box-shadow: 0 0 4px #958c8c;   border-bottom: none;color: whitesmoke">
    <h5>Interview Candidates</h5>
    <div class="button-group" style="display: flex; gap: 10px;">
      <button mat-button class="custom-button" (click)="toggleFilterForm()">
        <mat-icon>filter_list</mat-icon> Filter
      </button>
    </div>
  </div>
 
  <div class="filter-form-wrapper" *ngIf="showFilterForm">
    <div class="filter-header">
      <h2 style="color: black;">Filter</h2>
      <button mat-icon-button (click)="toggleFilterForm()">
        <mat-icon style="color: black;">close</mat-icon>
      </button>
    </div>
  
    <form #filterForm="ngForm" (ngSubmit)="onSubmitFilter()">
      <div class="form-group">
        <label for="dateRange">Select Date:</label>
        <div style="display:flex">
          <input [(ngModel)]="startDate" name="startDate" type="date" class="form-control"/>
          <input [(ngModel)]="endDate" name="endDate" type="date" class="form-control" style="margin-left: 10px;"/>
        </div>
      </div>
  
      <div class="form-group">
        <label>Status:</label>
        <select [(ngModel)]="status" name="status">
          <option value="SELECTED">Selected</option>
          <option value="REJECTED">Rejected</option>
          <option value="PENDING">Pending</option>
          <option value="INPROGRESS">In Progress</option>
          <option value="HIRED">Hired</option>
          <option value="SCREENING">Screening</option>
        </select>
      </div>
  
      <!-- <div class="form-group">
        <label>AI Status</label>
        <select [(ngModel)]="hiringAIStatus" name="hiringAIStatus">
          <option value="SELECTED">Selected</option>
          <option value="REJECTED">Rejected</option>
          <option value="AVERAGE">Average</option>
        </select>
      </div> -->
  
      <!-- <div class="form-group">
        <label for="designation">Designation:</label>
        <select [(ngModel)]="designation" name="designation">
          <option [value]="null">All</option>
          <option *ngFor="let candidate of candidates" [value]="candidate.designation">
            {{ candidate.designation }}
          </option>
        </select>
      </div> -->
  
      <!-- <div class="form-group">
        <label>Select Employee</label>
        <select [(ngModel)]="userId" name="userId">
          <option value="">Select Employee</option>
          <option *ngFor="let employee of employees" [value]="employee.id">
            {{ employee.userName }}
          </option>
        </select>
      </div> -->
  
      <div class="filter-btn">
        <button mat-button color="warn" (click)="clearFilters()">Clear</button>
        <button mat-raised-button color="accent" type="submit">Filter</button>
      </div>
    </form>
  </div>
  
<div class="container2 full-page">
  
    <div class="scrollable-content">
    
    <table class="candidates-table ">
      <thead>
        <tr>
          <th>E. ID</th>
          <th>Name</th>
          <th>Designation</th>
          <th>Source</th>
          <th>Acceptance Score</th>
          <th>Application Date</th>
          <th>URLS</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let candidate of hiring">
          <td>{{ candidate.id }}</td>
          <td>
            {{ candidate.name }}
            <a [href]="candidate.resume" target="_blank">
              <img src="../../../../assets/attach-file.png" alt="Resume" style="margin-left: 10px;">
            </a>
          </td>
          
          <td>{{ candidate.designation }}</td>
          <td>{{ candidate.createdByName }}</td>
          <!-- <td>
            <div class="acceptance-score">
              {{ candidate.matchedPercentage ? }}%
            </div>
          </td> -->
          <td>
            <div class="acceptance-score">
              <mat-icon style="font-size:9px" [ngClass]="{
                              'green': candidate.progressStatus === 'INPROGRESS',
                              'red': candidate.progressStatus === 'REJECTED',
                              'magenta': candidate.progressStatus === 'SELECTED'
                            }">circle</mat-icon>
              {{ candidate.matchedPercentage ? candidate.matchedPercentage.toFixed(2) : '0.00' }}%
            </div>
          </td>
          
          
          
          <td>{{ candidate.createdTime | date: 'dd/MM/yy' }}</td>

          <td>
            <div class="urls">
              <a *ngIf="hasUrl(candidate.urls, 'github')" [href]="getUrl(candidate.urls, 'github')" target="_blank">
                <img src="../../../../assets/github.png" alt="GitHub">
              </a>
              <a *ngIf="hasUrl(candidate.urls, 'linkedin')" [href]="getUrl(candidate.urls, 'linkedin')" target="_blank">
                <img src="../../../../assets/linkedin.png" alt="LinkedIn">
              </a>
              <a *ngIf="hasUrl(candidate.urls, 'portfolio')" [href]="getUrl(candidate.urls, 'portfolio')" target="_blank">
                <img src="../../../../assets/twitter.png" alt="Portfolio">
              </a>
            </div>
            
          </td>
          <td>{{ candidate.autoStatus }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="pagination-container">
  <mat-paginator 
    [length]="totalElements" 
    [pageSize]="pageSize" 
    [pageSizeOptions]="[10, 20, 50, 100]" 
    (page)="onPageChange($event)">
  </mat-paginator>
</div>
</div>