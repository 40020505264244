<div class="container2">
    <div class="breadcrumb-container" style="margin-top: 10px;">
      <div class="breadcrumb-item" [routerLink]="['/manager-dashboard/project-dashboard']">Assignment</div>
  
      <div class="breadcrumb-arrow">></div>
      <div class="breadcrumb-item" style="color:white">Task And Projects</div>
      <!-- <div class="breadcrumb-arrow">></div>
    <div class="breadcrumb-item">Project Details</div> -->
    </div>
    <div class="container">
      <div class="left-section">
        <div class="breadcrumb-container2">
          <div class="breadcrumb-item2" style="margin-top: -30px;">
            <div class="images"><img src="../../../../assets/icons/starting.png" alt="starting point" /><img style="    margin-left: 10px;
      margin-top: 4px;" src="../../../../assets/icons/connection.png" alt="" /></div>
          </div>
          <div class="breadcrumb-arrow"><img src="../../../../assets/icons/starting1.png" alt="second " /></div>
  
          <div class="breadcrumb-item2">Project Details</div>
          <div class="breadcrumb-arrow"><img src="../../../../assets/icons/connect.png" alt="connect" /></div>
          <div class="breadcrumb-item2"><img src="../../../../assets/icons/sqaure.png" alt="sqaure" /><span
              style="margin-left: 5px;">{{details.headings}}</span></div>
        </div>
        <div class="project-card">
          <div class="check" style="height: 28px;display: flex;">
            <img src="../../../../assets/icons/Ellipse.png">
            <div class="project-header1" style="margin-left: 10px;">
              <div class="project-title">{{details.headings}}</div>
              <div class="project-title2">{{details.subject}}</div>
  
            </div>
          </div>
          <div class="project-image">
            <img [src]="details.banner" alt="business" />
          </div>
          <div class="project-details">
            <div>
              <div class="detail-label">CREATED</div>
              <div class="detail-value">{{ details.createdDate | date: 'MMM d, hh:mm a' }}</div>
  
            </div>
            <div>
              <div class="detail-label">DEADLINE</div>
              <div class="detail-value">{{details.deadLine | date: 'MMM d, hh:mm a' }}</div>
            </div>
            <div>
              <div class="detail-label">MEMBERS</div>
              <div class="members">
                <div class="member member1" *ngFor="let photo of member"><img [src]="photo.assignToProfile" alt="photo"
                    style="width:25px;height:25px;border-radius: 50%;" /></div>
  
              </div>
            </div>
          </div>
        </div>
        <div class="project-card">
          <div class="project-header">
            <div class="detail-label"><img src="../../../../assets/icons/time.png" alt="created time" />Created By</div>
            <div class="detail-value">{{details.userName}}</div>
          </div>
          <div class="project-header">
            <div class="detail-label"><img src="../../../../assets/icons/Profile.png" alt="profile assign" /> Assign
            </div>
            <div class="detail-value">
              <div class="members">
                <div class="member member1" *ngFor="let photo of member"><img [src]="photo.assignToProfile" alt="photo"
                    style="width:25px;height:25px;border-radius: 50%;" /></div>
              </div>
            </div>
          </div>
          <div class="project-header" style="display: flex;">
            <div class="detail-label"><img src="../../../../assets/icons/Paper.png" alt="created time" />Project Files
            </div>
            <div class="detail-value" *ngFor="let file of details.files" style="margin-left: 50px;">
              <a [href]="file" target="_blank">
                <img [src]="getFileIcon(file)" alt="File" style="width:20px;height:20px;" />
              </a>
            </div>
  
          </div>
          <div class="project-header">
            <div class="detail-label"><img src="../../../../assets/icons/Star.png" alt="priority" />Priority</div>
            <div class="detail-value" [ngStyle]="{
              'background-color': getPriorityColor(details.priorityLevel),
              'color': '#FFFFFF',
              'padding': '5px 10px',
              'border-radius': '5px',
              'width': '67px'
            }">
              {{ details.priorityLevel ?? 'Low' }}
            </div>
          </div>
          <!-- <div class="project-header">
          <div class="project-details">
              <div class="detail-label"><img src="../../../../assets/icons/Star.png" alt="priority" />Priority</div>
              <div class="detail-value" style="background-color: #007bff; color: #FFFFFF; padding: 5px 10px; border-radius: 5px;">Medium</div>
        </div>
        
      </div> -->
        </div>
        <div class="project-stage" >
        <div class="todos-header" >
          <div class="todos-icon">
            <!-- <mat-icon>edit</mat-icon> -->
            <span style="  color: #cecece;
            font-size: 18px;
            text-decoration: underline;
            text-decoration-thickness: 1px;
            padding: 10px;
            text-underline-offset: 3px;">Project Stages</span>
          </div>
          <!-- <div class="add-task-icon">
            <img src="../../../../assets/icons/Frame 73.png" alt="story description-section" (click)="openDialog()" />
          </div> -->
        </div>
        <div style="overflow-y: auto;height: 300px;">
        <div class="stage-item completed" *ngFor="let stages of stage" >
          <img [src]="status === 'DONE'?'../../../../assets/icons/complete.png':'../../../../assets/icons/story.png'" alt="planning">
          <div class="stage-info">
            <span class="stage-name">{{stages.stageName}}</span>
            <span class="stage-date" [ngClass]="getStatusClass(stages.status)">{{stages.status}}</span>
            <span class="stage-date">{{stages.completionTime}}</span>
          </div>
          <!-- <button (click)="updateStage(stages.stageId)" class="edit-button" ><mat-icon>edit</mat-icon></button> -->
        </div>
        </div>
      </div>
  
        <div class="description-section">
          <div class="description-header">
            <div class="description-text-head">Description</div>
            <!-- <div class="description-icon" (click)="openDialogDescription()">
              <mat-icon>edit</mat-icon>
            </div> -->
          </div>
        
          <!-- Conditionally show div or textarea -->
          <div *ngIf="!isEditing" class="description-text">
            {{ description }}
          </div>
          <div *ngIf="isEditing" class="description-text">
            <textarea [(ngModel)]="description"></textarea>
            <div class="description-actions">
              <button (click)="saveDescription()">Save</button>
              <button (click)="cancelEdit()">Cancel</button>
            </div>
          </div>
        </div>
        
        
      </div>
      <div class="right-section">
        <div class="todos-header">
          <div class="todos-icon">
            <img src="../../../../assets/icons/Category.png" alt="story description-section" /> <span style="  color: #cecece;
            font-size: 18px;
            text-decoration: underline;
            text-decoration-thickness: 1px;
            padding: 10px;
            text-underline-offset: 3px;">Todos</span>
          </div>
          <div class="add-task-icon">
            <img src="../../../../assets/icons/Frame 73.png" alt="story description-section" (click)="openDialog()" />
          </div>
        </div>
        <div class="task-card" >
          <div class="task-checkbox">
            <!-- <input type="checkbox" checked> -->
            <button class="task-name" [routerLink]="['/user-dashboard/subtask-assignment-details/']"
            [queryParams]="{  assignId: projectId }">
            View  Task
            </button>
          </div>
        </div>
       
        <div class="todos-header">
          <div class="todos-icon"><span style="  color: #cecece;
            font-size: 18px;
            text-decoration: underline;
            text-decoration-thickness: 1px;
            padding: 10px;
            text-underline-offset: 3px;">Notes</span>
          </div>
          <!-- <div class="add-task-icon">
            <img src="../../../../assets/icons/Frame 73.png" alt="story description-section" (click)="openDialogNote()" />
          </div> -->
        </div>  
        <div class="notes-container">
          <div class="item" *ngFor="let note of (details?.notes || []).slice().reverse(); let i = index">
            <div class="header">
              <div class="date" style="display: flex; justify-content: space-between;">
                <img src="../../../../assets/icons/Paper.png" alt="paper" />
                <!-- <div class="todos-icon">
                  <img src="../../../../assets/icons/Vector.png" alt="edit"  style="cursor: pointer;" (click)="openDialognotes(i)" />
                </div> -->
              </div>
              <div class="icon icon-more"></div>
            </div>
            <div class="body">
              <p>{{ note }}</p>
            </div>
          </div>
        </div>
        
        
  
      </div>
    </div>