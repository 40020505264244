<mat-dialog-content>
  <div (click)="closeModal()"></div>
  <div class="modal-content">
    <span
      class="close"
      (click)="closeModal()"
      style="color: white; cursor: pointer; float: right"
      >&times;</span
    >
    <h2 style="color: white">{{ isUpdateMode ? 'Update Stage' : 'Add Stage' }}</h2>
    <form (ngSubmit)="formSubmit()" #stageForm="ngForm">
      <label for="stageName" style="color: white">Stage Name</label>
      <input
        id="stageName"
        name="stageName"
        [(ngModel)]="stage.stageName"
        required/>
      <label for="status" style="color: white">Status</label>
      <select id="status" name="status" [(ngModel)]="stage.status" required>
        <option value="INITIALIZED">INITIALIZED</option>
        <option value="INPROGRESS">INPROGRESS</option>
        <option value="PENDING">PENDING</option>
        <option value="COMPLETED">COMPLETED</option>
      </select>
      <button type="submit" [disabled]="!stageForm.form.valid">
        {{ isUpdateMode ? 'Update' : 'Save' }}
      </button>
    </form>
  </div>
</mat-dialog-content>
