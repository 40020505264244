import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CandidateModalComponent } from '../candidate-modal/candidate-modal.component';
import { MainService } from 'src/app/MyService/main.service';
import { PageEvent } from '@angular/material/paginator';
import { CandidateSaveComponent } from '../candidate-save/candidate-save.component';
import * as _ from 'lodash';
@Component({
  selector: 'app-hiring-candidate',
  templateUrl: './hiring-candidate.component.html',
  styleUrls: ['./hiring-candidate.component.css']
})
export class HiringCandidateComponent implements OnInit {
  candidates: any[] = [];
  hiring: any;
  sortOrder: 'asc' | 'desc' = 'asc';
  sortedByDateAsc :'asc' | 'desc' = 'asc';
  pageNumber: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  userId: any;
  vacancyId: any;
  isActiveVacancy: boolean | null = null;
  hiringAIStatus: string | null = null;
  status: string | null = null;
  startDate: any = null;
  endDate: any = null;
  designation: string | null = null;
  showFilters: boolean = false;
  showPopup = false;
  inputDateToString:any;
  inputDateFromString:any;
  showFilterForm: Boolean = false;
  truncateLimit = 15;
  constructor(public dialog: MatDialog, private mainService: MainService) {}

  ngOnInit(): void {
    this.fetchVacancies();
    this.fetchData();
  }

  fetchVacancies(): void {
    this.mainService.getAllvacancy().subscribe((res: any) => {
      this.candidates = res;
      console.log("Vacancies: ", this.candidates);
    });
  }

  applyFilters(): void {
    this.pageNumber = 0; 
    this.fetchData();
  }

  fetchData(): void {
    this.inputDateFromString = this.mainService.toTimestamp(this.startDate);
    this.inputDateToString = this.mainService.toTimestamp(this.endDate);
    this.mainService
      .getAllhiring(
        this.userId,
        this.vacancyId,
        this.isActiveVacancy,
        this.hiringAIStatus,
        this.status,
        this.inputDateFromString,
        this.designation,
        this.inputDateToString,
        this.pageNumber,
        this.pageSize
      )
      .subscribe((res: any) => {
        this.hiring = res.content;
        this.totalElements = res.totalElements;
        console.log("Hiring Data: ", this.hiring);
      });
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData();
  }

  filterStatus(status: string): void {
    this.status = status;
    this.applyFilters();
  }
  toggleFilters(): void {
    this.showFilters = !this.showFilters;
  }
 
  updateStatus(vacancyId: any, status: boolean):void {
  //  const isActive= status ===true;
    this.mainService.updatevacancy(vacancyId,status).subscribe(
      response => {
        console.log('Status updated successfully', response);
        this.fetchData();
        window.location.reload();
      },
      error => {
        console.error('Error updating status', error);
      }
    );
  }

  openModalDialog(id?: number): void {
    const dialogRef = this.dialog.open(CandidateModalComponent, {
      width: '585px',
      data: { id: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openSaveDialog(): void {
    const dialogRef = this.dialog.open(CandidateSaveComponent, {
      width: '585px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  hasUrl(urls: string[] | null | undefined, type: string): boolean {
    return urls ? urls.some(url => this.getType(url) === type) : false;
  }

  getUrl(urls: string[], type: string): string | null {
    const url = urls.find(url => this.getType(url) === type);
    return url ? url : null;
  }

  getType(url: string): string {
    if (url.includes('github.com')) {
      return 'github';
    } else if (url.includes('linkedin.com')) {
      return 'linkedin';
    } else if (url.includes('x.com') || url.includes('twitter.com')) {
      return 'portfolio';
    }
    return '';
  }

  toggleFilterForm(){
    this.showFilterForm = !this.showFilterForm;
  }
  isTruncated(text: string): boolean {
    return text?.length > this.truncateLimit;
  }
  sortCandidatesById(): void {
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    // console.log("date check" + id);
    this.hiring = _.orderBy(this.hiring, ['id'], [this.sortOrder]);
  }
  // sortCandidatesByDates():void{
  //   console.log("prin sortedByDateAsc"+ this.sortOrder )
  //   this.sortedByDateAsc = this.sortedByDateAsc === 'asc' ? 'desc' : 'asc';
  //   console.log("date check" + this.sortedByDateAsc);
  //   this.hiring.forEach(candidate => {
  //     console.log("Candidate createdTime: " +  candidate.matchedPercentage);
  //   });
  //   this.hiring = _.orderBy(this.hiring, ['matchedPercentage '], [this.sortedByDateAsc]);
   
  // }
}
